import * as React from "react";
import { graphql, StaticQuery } from "gatsby";

import zparse from 'helpers/zparse';
import Accordion from "global/Accordion";
import DataProvider from "providers/data";

const JobApplicationFAQs = (props) => {
	return (
		<StaticQuery
			query={graphql`
        query JobFAQQuery {
          allJobQuestion(
            filter: {
              archived: { eq: false }
              approved: { eq: true }
              deleted: { eq: false }
            }
          ) {
            edges {
              node {
                question
                answer
              }
            }
          }
        }
      `}
			render={(data) => {
				const { edges } = data.allJobQuestion;
				let faqs = edges.map(({ node }) => node);
				if ((props.providerResponse.data.length > 0 || !!faqs !== false) && props.providerStatusText === "LOADED") {
					faqs = props.providerResponse.data;
				}

				return (
					<section id="career-faqs">
						<div className="grid-container">
							{!!faqs.length && <h2 className="text-center">FAQ - Careers at CenTrio</h2>}
							<div className="grid-x grid-padding-x align-center">
								<div className="cell small-12 medium-10">
									{!!faqs.length &&
										faqs.map(({ question, answer }, index) => (
											<div key={`faq-${index}`} className="cell-wrapper">
												<Accordion title={question}>
													{zparse(answer)}
												</Accordion>
											</div>
										))}
								</div>
							</div>
						</div>
					</section>
				);
			}}
		/>
	);
};

const getData = (props) => (
	<DataProvider liveRefresh={true} type="JobQuestion">
		<JobApplicationFAQs {...props} />
	</DataProvider>
);
export default getData;
