import React, { Component } from 'react'
import * as style from 'styles/global/accordion.module.scss';
import {Transition, animated} from 'react-spring/renderprops';

class Accordion extends Component {
	constructor(props) {
		super(props);
		this.expand = this.expand.bind(this);
		this.collapse = this.collapse.bind(this);
		this.state = {
			show: false
		};
	}

	toggle = () => {
		if (this.state.show)this.collapse();
		else this.expand();
	}

	expand(props = {}) {
		this.setState({
			show: true,
		});
		if (props.fireEvent !== false && this.props.onExpand !== undefined)this.props.onExpand();
	}

	collapse(props = {}) {
		this.setState({
			show: false,
		});
		if (props.fireEvent !== false && this.props.onCollapse !== undefined)this.props.onCollapse();
	}

	componentDidMount() {
		if (this.props.open) {
			this.setState({show:this.props.open});
		}
	}

	render() {
		const title = this.props.title;
		const body = this.props.body||this.props.children;

		return (
			<div className={style.container}>
				<div role="presentation" onClick={this.toggle} className={`${style.title}`}><div className={`${style.icon} ${this.state.show ? style.open : ""}`}>❖</div>{title}</div>
				<Transition
				native
				items={this.state.show}
				from={{ opacity: 0, height: 0 }}
				enter={[{ opacity: 1, height: 'auto' }]}
				leave={{ opacity: 0, height: 0, overflow: 'hidden' }}>
				{show =>
					show && (props => <animated.div ref={this.textInput} style={props} className={style.body}>{ body }</animated.div>)
				}
				</Transition>
			</div>
		)
	}
}

export default Accordion;