import * as React from "react";
import { graphql, StaticQuery } from "gatsby";

import DataProvider from "providers/data";
import Link from "components/Link"

const JobOpportunityGrid = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query JobGridQuery {
          allJobOpportunity(
            filter: {
              archived: { eq: false }
              approved: { eq: true }
              deleted: { eq: false }
            }
          ) {
            edges {
              node {
                title
                summary
                link
              }
            }
          }
        }
      `}
      render={(data) => {

        const { edges } = data.allJobOpportunity;
        let opportunities = edges.map(({ node }) => node);
        if ((props.providerResponse.data.length > 0 || !!opportunities !== false) && props.providerStatusText === 'LOADED') {
          opportunities = props.providerResponse.data;
        }

        return (
          <section id="career-opportunities">
            <div className="grid-container">
              <h2 className="text-center">Job Opportunities</h2>
              <div className="grid-x grid-padding-x small-up-1 medium-up-2">
                {!!opportunities.length && opportunities.map(({ title, summary, link }, index) => (
                  <div key={`opportunity-${index}`} className="cell">
                    <div className="cell-wrapper">
                      <h3>{title}</h3>
                      <p>{summary}</p>
                      <Link external="true" to={link}>Apply Now</Link>
                    </div>
                  </div>
                ))}
              </div>
              {opportunities.length === 0 && <div id="no-ops" className="text-center"><strong>Thank you for your interest in opportunities at CenTrio. Although, we currently do not have any relevant job openings, we recommend that you still connect with us, as positions may become available in the future. Please submit your cover letter and resume to <Link to={`mailto:HR@centrioenergy.com`}>HR@centrioenergy.com</Link>.</strong></div>}
            </div>
          </section>
        );
      }}
    />
  );
};

const getData = (props) => (
  <DataProvider liveRefresh={true} type="JobOpportunity">
    <JobOpportunityGrid {...props} />
  </DataProvider>
);
export default getData;
