import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';

import DataProvider from 'providers/data';

import Layout from 'templates/Layout';
import Content from 'templates/Content';

import { default as Card} from 'components/GridCard';

import JobApplicationFAQs from 'components/careers/JobApplicationFAQs';
import JobOpportunityGrid from 'components/careers/JobOpportunityGrid';

const ContentPage = (props) => {
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!page !== false) && props.providerStatusText === 'LOADED') {
		page = props.providerResponse.data[0];
	}
	if (!!page === false && props.providerStatus >= 2) return <NotFound />; // We only go to a page not found after the API loads or has an error and there's no page found.
	if (props.providerStatus < 2) { // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
		return null;
	}

	return (
		<div className={`loadWrap ${props.providerStatus >= 2 ? 'loaded' : ''}`}>
			{ props.providerStatus >= 2 &&
				<Layout showContactForm={page?.showContactForm} showNewsletterSignUp={page?.showNewsletterSignUp} hoverboards={page?.hoverboards}>
					<Content {...page}>
						{!!page?.solutions?.length && page?.solutions.map((card, index) => (
							<Card description={card.body} {...card} columnSize="6" key={`content-card-${index}`} order={index+1} />
						))}
					</Content>
					{props.location.pathname === "/careers/" && <JobApplicationFAQs />}
					{props.location.pathname === "/careers/" && <JobOpportunityGrid />}
				</Layout>
			}
		</div>
	)
}

// export default ContentPage
const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ContentPage" apiParams={{ uri: props.location.pathname }}><ContentPage {...props} /></DataProvider>
export default getData;

export const query = graphql`
	query ($uri: String) {
	  allContentPage(
	    filter: {uri: {eq: $uri}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  ) {
		edges {
			node {
			  pageTitle
			  meta
			  content {
				main
			  }
			  showContactForm
			  showNewsletterSignUp
			  headlineBody
			  headlineTitle
			  headlineImage
			  headlineType
			  hoverboards

			  solutions
			}
		  }
		}
	}
`